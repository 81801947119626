const env = process.env.NODE_ENV === 'development';

const API_URL = env
  ? 'https://api.caribemaya.com.mx/api/v1'
  : 'https://api.caribemaya.com.mx/api/v1';
const API_URL_BACK = env ? 'http://localhost:8000/api/' : 'https://api.caribemaya.com.mx/api/';
const API_URL_BACK_ITEMS = env
  ? 'https://api.customer.viajescaribemaya.com/api/'
  : 'https://api.customer.viajescaribemaya.com/api/';
const USER_ID = env ? 326 : 326;
// export const axiosApi = Axios.create({
//   baseURL: 'https://reqres.in/api/',
// });
export { API_URL, API_URL_BACK, USER_ID, API_URL_BACK_ITEMS };
