import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PrivateRoute } from './components/privateRoute';
import { LoginPage } from './pages/login';

const listClients = lazy(() => import('./pages/client/index'));
const detailsCliente = lazy(() => import('./pages/client/details'));
const history = lazy(() => import('./pages/client/history'));

const Loading = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgba(24,144,255,10%)',
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src="https://flevix.com/wp-content/uploads/2019/07/Alternate-Preloader.gif" alt="" />
    </div>
  );
};
const Router = ({ isAuth, user }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/lista/clientes" />} />
        <Route exact path="/login" component={LoginPage} />
        <PrivateRoute exact path="/lista/clientes" component={listClients} auth={isAuth} />
        <PrivateRoute
          exact
          path="/details/client/:id_reservation"
          component={detailsCliente}
          auth={isAuth}
        />
        <PrivateRoute exact path="/historial/clientes" component={history} auth={isAuth} />

        <Route component={() => <div>Error 404: No se encontró la página</div>} />
      </Switch>
    </Suspense>
  );
};
export default Router;
