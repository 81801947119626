import { loginTypes } from '../constants';
import { userService } from '../services/user-services';
import { alertActions } from './alert-actions';
import { history } from '../store/history';
import ability from '../conf/ability';
import { some } from 'lodash';

export const userActions = {
  login,
  logout,
  register,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    return userService
      .login(username, password)
      .then((user) => {
        const { data } = user;
        console.log(data);
        // hacer merge de todos los permisos (Back or Front)
        // const permissions = data.roles[0].permissions.map((e) => ({
        //   subject: 'Extranet',
        //   actions: [e.name.replace(/\s/g, '')],
        // }));
        const permissions = data.permissions;
        // actualizamos las reglas y agregamos una regla general....
        ability.update([{ subject: 'Extranet', actions: 'read' }, ...permissions]);
        dispatch(success({ token: data.token, permissions, user: data.user }));
        //Los usuarios pueden tener mas de un rol pero un proveedor jamas puede ser administrador
        console.log(some(data.roles, ['name', 'PROVEEDORES']));
        if (some(data.roles, ['name', 'PROVEEDORES'])) {
          history.push('/proveedor/confirmation');
        } else if (some(data.roles, ['name', 'ENCARGADO'])) {
          history.push('/cuponesE');
        } else if (some(data.roles, ['name', 'AUXILIAR'])) {
          history.push('/cuponesA');
        } else if (some(data.roles, ['name', 'CMS'])) {
          history.push('/cms/parques');
        } else {
          history.push('/lista/clientes');
        }

        dispatch(alertActions.success('Login Success'));
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(failure(JSON.stringify(error)));
        return Promise.reject(error);
      });
  };
  function request(user) {
    return { type: loginTypes.LOGIN_REQUEST, user };
  }
  function success(data) {
    return { type: loginTypes.LOGIN_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: loginTypes.LOGIN_ERROR, error };
  }
}

function logout() {
  console.log('desloguiao');
  ability.update([]); // Elimina todos los permisos.
  return { type: loginTypes.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.register(user).then(
      (user) => {
        dispatch(success());
        history.push('/login');
        dispatch(alertActions.success('Registration successful'));
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: loginTypes.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: loginTypes.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: loginTypes.REGISTER_FAILURE, error };
  }
}

// function getAll() {
//   return (dispatch) => {
//     dispatch(request());

//     userService
//       .getAll()
//       .then(users => dispatch(success(users)), error => dispatch(failure(error.toString())));
//   };

//   function request() {
//     return { type: loginTypes.GETALL_REQUEST };
//   }
//   function success(users) {
//     return { type: loginTypes.GETALL_SUCCESS, users };
//   }
//   function failure(error) {
//     return { type: loginTypes.GETALL_FAILURE, error };
//   }
// }

// function _delete(id) {
//   return (dispatch) => {
//     dispatch(request(id));

//     userService
//       .delete(id)
//       .then(user => dispatch(success(id)), error => dispatch(failure(id, error.toString())));
//   };

//   function request(id) {
//     return { type: loginTypes.DELETE_REQUEST, id };
//   }
//   function success(id) {
//     return { type: loginTypes.DELETE_SUCCESS, id };
//   }
//   function failure(id, error) {
//     return { type: loginTypes.DELETE_FAILURE, id, error };
//   }
// }
